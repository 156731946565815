/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/highlightjs@9.16.2/highlight.pack.min.js
 * - /npm/axios@0.19.2/dist/axios.min.js
 * - /npm/dlv@1.1.3/dist/dlv.umd.min.js
 * - /gh/alpinejs/alpine@2.2.1/dist/alpine.js
 * - /npm/fuse.js@5.0.9-beta/dist/fuse.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
